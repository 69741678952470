import styled from '@emotion/styled'
import { CookieMessage } from 'app/components/Common/CookieMessage'
import { Toolbars } from 'app/components/Common/Toolbars'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import * as Icon from 'react-feather'
import ReactGA from 'react-ga'

export interface Props {
  address?: string
  country?: string
  email?: string
  facebookURL?: string
  fax?: string
  IBEID?: number
  instagramURL?: string
  languageCode: string
  languagePrefix?: string
  logo?: { [key: string]: any }
  mobilePhone?: string
  phone?: string
  postalCode?: string
  locality?: string
  siteName?: string
  vat?: string
}

export const Footer = memo(function Footer({
  address,
  country,
  email,
  facebookURL,
  fax,
  instagramURL,
  IBEID,
  languageCode,
  languagePrefix,
  logo,
  mobilePhone,
  phone,
  postalCode,
  locality,
  siteName,
  vat,
}: Props) {
  return (
    <Container>
      {logo ? (
        <Logo
          src={logo.src}
          alt={siteName}
          width={logo.width}
          height={logo.height}
        />
      ) : null}
      {address ? (
        <Address>{`${address} - ${postalCode} ${locality} ${country}`}</Address>
      ) : null}
      <Contacts dial={2} row>
        {phone ? (
          <Phone
            href={`tel:${phone}`}
            onClick={() => {
              if (isMobile) {
                ReactGA.event({
                  category: 'Website',
                  action: 'Click',
                  label: 'Mobile Phone',
                })
              }
            }}
          >
            {`${useVocabularyData('phone', languageCode)} ${phone}`}
          </Phone>
        ) : null}
        {mobilePhone ? (
          <MobilePhone
            href={`tel:${mobilePhone}`}
            onClick={() => {
              if (isMobile) {
                ReactGA.event({
                  category: 'Website',
                  action: 'Click',
                  label: 'Mobile Cell Phone',
                })
              }
            }}
          >
            {`${useVocabularyData(
              'mobile-phone',
              languageCode,
            )} ${mobilePhone}`}
          </MobilePhone>
        ) : null}
        {fax ? (
          <Fax>{`${useVocabularyData('fax', languageCode)} ${fax}`}</Fax>
        ) : null}
      </Contacts>
      {email ? (
        <Email
          href={`mailto:${email}`}
          onClick={() => {
            ReactGA.event({
              category: 'Website',
              action: 'Click',
              label: 'Email address',
            })
          }}
        >
          {email}
        </Email>
      ) : null}
      <Social dial={5} row>
        {facebookURL ? (
          <SocialItem
            aria-label="Facebook"
            href={facebookURL}
            rel="noreferrer"
            target="_blank"
          >
            <Icon.Facebook />
          </SocialItem>
        ) : null}
        {instagramURL ? (
          <SocialItem
            aria-label="Instagram"
            href={instagramURL}
            rel="noreferrer"
            target="_blank"
          >
            <Icon.Instagram />
          </SocialItem>
        ) : null}
      </Social>
      <Policy dial={2} row>
        <Link
          to={`${languagePrefix ? `/${languagePrefix}` : ''}/privacy-policy`}
        >
          {useVocabularyData('privacy-policy', languageCode)}
        </Link>
        <Link
          to={`${languagePrefix ? `/${languagePrefix}` : ''}/cookie-policy`}
        >
          {useVocabularyData('cookie-policy', languageCode)}
        </Link>
      </Policy>
      <Bottom dial={2} row space="between">
        {vat ? (
          <Vat>{`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
        ) : null}
        {siteName ? (
          <SiteName>
            © {new Date().getFullYear()} {siteName}
          </SiteName>
        ) : null}
        <Credits href="https://www.qnt.it" target="_blank" rel="noopener">
          Credits QNT Srl
        </Credits>
      </Bottom>
      <CookieMessage
        languageCode={languageCode}
        languagePrefix={languagePrefix}
      />
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="ipadVertical">
          <Toolbars
            languageCode={languageCode}
            languagePrefix={languagePrefix || null}
            email={email}
            phone={phone}
            IBEID={IBEID}
          />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 0 1.5rem;
  text-align: center;
`

const Logo = styled.img`
  margin: 3.75rem auto 3rem;
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  line-height: 3rem;
`

const Contacts = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  line-height: 3rem;

  @media (max-width: 767px) {
    display: block;
  }
`

const Phone = styled.a`
  display: inline-flex;
  align-items: center;
  &:after {
    content: '';
    width: 5px;
    height: 5px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 50%;
    margin: 0 0.5625rem;
  }

  @media (max-width: 767px) {
    display: block;
    &:after {
      display: none;
    }
  }
`

const MobilePhone = styled.a`
  display: inline-flex;
  align-items: center;
  &:after {
    content: '';
    width: 5px;
    height: 5px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 50%;
    margin: 0 0.5625rem;
  }

  @media (max-width: 767px) {
    display: block;
    &:after {
      display: none;
    }
  }
`

const Fax = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

const Email = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  line-height: 3rem;
  text-decoration: underline;
`

const Social = styled(FlexBox)`
  margin-top: 2.4375rem;
`

const SocialItem = styled.a`
  opacity: 0.3;
  margin: 0 0.5625rem;
  transition: 0.3s ease-out;
  svg {
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    height: 24px;
  }
  &:hover {
    opacity: 1;
  }
`

const Policy = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 0.875rem;
  margin-top: 3rem;
  text-transform: uppercase;
  a {
    display: flex;
    &:last-of-type {
      &:after {
        display: none;
      }
    }
    &:after {
      content: '|';
      margin: 0 0.75rem;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`

const Bottom = styled(FlexBox)`
  max-width: 73.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  letter-spacing: 1px;
  line-height: 0.875rem;
  margin: 2.25rem auto 0;
  padding-bottom: 2.125rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    display: block;
  }
`

const Vat = styled.div`
  opacity: 0.3;
`

const SiteName = styled.div`
  opacity: 0.3;

  @media (max-width: 767px) {
    margin: 0.75rem auto;
  }
`

const Credits = styled.a`
  opacity: 0.3;
  transition: 0.3s ease-out;
  &:hover {
    opacity: 1;
  }
`
